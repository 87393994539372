import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { RichText } from 'prismic-reactjs'

import StaffAndBoardModalButton from "./StaffAndBoardModalButton"

const StaffAndBoardWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;

  @media(min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

const StaffAndBoard = props => {
  const data = useStaticQuery(graphql`
    {
      genericPortrait: file(relativePath: { eq: "generic-person@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allPrismicStaffBoardCard(sort: {fields: data___last_name___text, order: ASC}) {
        edges {
          node {
            data {
              title {
                text
              }
              position
              photo {
                alt
                localFile {
                  childImageSharp {
                    fluid(trim: 1, cropFocus: CENTER) {
                      srcSet
                      src
                    }
                  }
                }
              }
              last_name {
                text
              }
              first_name {
                text
              }
              bio {
                raw
              }
            }
          }
        }
      }
      staff: allMarkdownRemark(filter:
          {frontmatter:
            {service:
              {eq: "Staff"}
            }
          },
          sort:{fields:frontmatter___sortOrder, order: ASC}
        )
      {
          edges {
            node {
              frontmatter {
              firstname
              lastname
              info
              service
              ages
              business
              bio
                photo {
                  childImageSharp {
                    fluid(maxWidth: 200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
  `)



  const staffBoard = data.allPrismicStaffBoardCard.edges
  const genericPerson = data.genericPortrait.childImageSharp.fluid
  return (
    <>
      <h2 className="sectionTitle" style={{marginTop: 0}}>Our Staff and Board Members</h2>
    <StaffAndBoardWrapper>
      {staffBoard.map(({ node }) => {
        return (
          <StaffAndBoardModalButton 
            staffName={node.data.first_name.text}
            lastName={node.data.last_name.text}
            staffPosition={node.data.position}
            info={node.data.title.text}
            imgFluid={node.data.photo.localFile.childImageSharp.fluid === null ? genericPerson : node.data.photo.localFile.childImageSharp.fluid}
            bio={<RichText render={node.data.bio.raw} />}
          />
        )
      })
    }
    </StaffAndBoardWrapper>
    </>
  )
}

export default StaffAndBoard
