import React from "react";
import styled from "styled-components"

const Amoeba = styled.svg `
      width: 101% !important;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      transform: rotate(180deg);
      margin-top: -2px;

`;

const AmoebaSlim = (props) => {
    return (
      <Amoeba xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8191.99979 795.27938" preserveAspectRatio="none" style={{width: '101% !important'}}>
      <path fill={props.fillColor} id="Path_6032" data-name="Path 6032" d="M8190.771,0C7980.4822,164.33152,7299.80942,204.39039,6939.115,320.30719c-367.08359,117.5552-1333.00236,82.57535-1835.82715,181.207-366.26439,71.68-1205.28877,14.336-1548.37,34.57025-954.61338,56.44285-1267.71157-86.8352-2111.48755-136.151C747.60188,359.46494,353.15712,163.92192,0,25.31328v769.9661H8191.99979V0Z"/>
      
</Amoeba>


// <Amoeba xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1943.80005 188.69488">

//           <path fill={props.fillColor} id="Path_6032" data-name="Path 6032" class="cls-1" d="M1943.5,0c-49.9,39-211.4,48.5-297,76-87.1,27.9-316.30005,19.6-435.6,43-86.9,17-286,3.4-367.4,8.2-226.5,13.4-300.8-20.6-501-32.3C177.4,85.3,83.8,38.9,0,6V188.69488H1943.80005V0Z"/>

// </Amoeba>
    )
}
export default AmoebaSlim
