import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"
import AmoebaSlimOverImage from "../Amoebas/AmoebaSlimOverImage"

const PageHeroBottom = props => {
  return (
    <Container fluid>
      <div
        className=""
        style={{
          position: "relative",
          backgroundColor: "#D8D8F6",
          marginTop: '-2px'
        }}
      >
        <div>
          <Img
            fluid={props.bottomImage}
            alt=""
            style={{ height: "100%", maxHeight: "800px" }}
          />
        </div>
            <AmoebaSlimOverImage
              fillColor={props.fillColor}
            />
        <div
              style={{
                position:'absolute',
                bottom: 0,
                padding: "30px",
                width: "100%",

              }}
            >
              <Container>
                <Row>
                  <Col>
                  <h1 className="heroHeading" 
                  style={{
                    textAlign: 'center',
                  }}>{props.heading}</h1>
                  </Col>
                </Row>
              </Container>
              
                
              
            </div>
      </div>
    </Container>
  )
}

export default PageHeroBottom
