import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHero from '../components/PageHero/PageHero';
import StaffAndBoard from '../components/HomePage/StaffAndBoard/StaffAndBoard';
import AmoebaSlim from '../components/Amoebas/AmoebaSlim'
import PageHeroBottom from '../components/PageHero/PageHeroBottom';

import { Container, Row, Col } from 'react-bootstrap';

const AboutPage = (props) => { 
    const document = props.data.allPrismicPage.edges[0].node.data

    const prismicHeroImage = props.data.allPrismicPage.edges[0].node.data.hero_image.localFile
    const bottomPhoto = props.data.wheatField.childImageSharp.fluid
    
    return (
      <Layout>
        <SEO title="Home" />

        <PageHero
          heading={document.hero_title.text}
          //description={<div dangerouslySetInnerHTML={{ __html: body}} />}
          description={
            <>
              <RichText render={document.hero_content.raw} />
            </>
          }
          image={prismicHeroImage}
          alt={props.data.allPrismicPage.edges[0].node.data.hero_image.alt}
          //mobileImage={heroMobilePhoto}
          style={{ color: "#C97D60" }}
          fillColor="#434081"
          bgColor="#434081"
        />

<Container>
              <Row>
                <Col style={{marginTop: '3rem'}}>
                  <h3 className="text-center subSectionTitle" style={{fontSize: '2rem'}}>
                    Our mission is to promote mental wellness in Teton Valley
                    through education, community action, and the coordination of
                    resources.{" "}
                  </h3>
                  <p className="purpleText"
                    style={{
                        fontWeight: 300,
                        fontSize: "1.2rem",
                    }}
                  >
                    We are mental health providers, small business owners, parents, medical professionals, local leaders, and dedicated community members who share a common passion for fostering mental health in Teton Valley. Teton Valley Mental Health Coalition formed in the Fall of 2009 and later became a 501(c)(3) nonprofit to help foster collaboration among mental health care providers in the community. We are dedicated to addressing emergent community mental health needs and fostering mental wellness through the following programs and services:
                  </p>
                </Col>
              </Row>
            </Container>
                <div style={{ bottom: 0 }}>
          <AmoebaSlim
            style={{ width: "auto", height: "100%", bottom: 0 }}
            fillColor={"#87C3CC"}
          />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              padding: "10px",
              marginTop: "10px",
            }}
          />
          <Container
            fluid
            style={{
              background: "#87C3CC",
              marginTop: "-5px"
            }}
          >

            <Container>
                <StaffAndBoard />
            </Container>
          </Container>
        </div>
        <PageHeroBottom
      fillColor="#87C3CC"
      bottomImage={bottomPhoto}
      heading="Teton Valley’s mental health matters and we’re here to support you."
    />
      </Layout>
    )
}

export const query = graphql`
{
  allPrismicPage(filter: {uid: {eq: "about"}}) {
    edges {
      node {
        data {
          hero_title {
            text
          }
          hero_content {
            raw
          }
          hero_image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
  holdingHands: file(relativePath:{eq:"holding-hands@2x.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2500, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  holdingHandsMobile: file(relativePath:{eq:"holding-hands-mobile.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2500, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  tetonsMoonrise: file(relativePath:{eq:"TetonsMoonrise.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2500, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  wheatField: file(relativePath:{eq:"wheatField.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2500, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

export default AboutPage;